<template>
  <div :class="{
      'show-all' : notes.length > 1,
      'show-action' : showActionButton
    }"
  >
    <div
      class="field-header"
      v-if="showHeadline || showActionButton"
    >
      <div
        class="title"
        v-if="showHeadline"
      >
        {{$t('title.notes')}}
      </div>
      <div class="action">
        <a
          class="btn cur-p"
          @click="seeAllNotes()"
        >
          {{$t('button.seeAll')}}
        </a>
      </div>
    </div>
    <div class="field-content">
      <div
        v-for="(note, key) in notes"
        :key="key"
        class="note"
        v-show="key < 1 || showAll"
      >
        <div class="note-head field-header">
          <div
            class="avatar"
            :style="{
              'background-image': 'url(' + getPhotoUrlOrNoPhoto(note.owner.photo_url) + ')'
            }"
          />
          <div class="note-meta">
            <div class="author">
              {{ note.owner.name ? note.owner.name : note.owner.first_name }}
              {{ note.owner.surname ? note.owner.surname : note.owner.last_name }}
            </div>
            <div class="date">{{ $moment(note.created_at).format('lll') }}</div>
          </div>
          <div
            v-if="note.text.length > 210"
            class="collapse-action-block"
          >
            <button
              v-if="toggledId !== note.id"
              class="collapse-action"
              @click="showText(note.id)"
            >
              {{  $t('button.more') }}
            </button>
            <button
              v-else
              class="collapse-action"
              @click="hideText()"
            >
              {{ $t('button.less') }}
            </button>
          </div>
          <div
            class="cur-p fav"
            @click.stop="toggleNoteFav(note.id, note)"
          >
            <icon-star-toggler
              :is-active="note.is_favorite"
              :color="note.is_favorite ? '#00b6f8' : 'gray'"
            />
          </div>
        </div>

        <div class="content-text">
          <div class="note-title">{{ note.title }}</div>
          <div
            class="note-text"
            v-if="note.text"
          >
            <perfect-scrollbar class="truncate-scroll-height">
              <div
                class="note-text"
                :class="{ collapsed: toggledId !== note.id }"
              >
                <span v-html="linkifyUrls(getTruncatedText(note.text, (toggledId === note.id ? 9999 : 210)))" />
              </div>
            </perfect-scrollbar>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { DateTimeHelper } from '@/helpers/date-time.helper';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import IconStarToggler from '@/_modules/icons/components/icon-star-toggler.vue';

export default {
  name: 'notesList',
  components: {
    IconStarToggler,
  },
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
    showAll: { // Show all notes, not just 4
      type: Boolean,
      default: false
    },
    showActionButton: { // Display or don't display the «See all...»
      type: Boolean,
      default: false
    },
    showHeadline: { // Display or don't display the block headline
      type: Boolean,
      default: true
    },
    noteTruncateLength: {
      type: Number,
      default: 120
    },
  },
  data: function () {
    return {
      notesList: [],
      toggledId: null,
      isCollapsed: true,
    }
  },
  methods: {
    addOffset(dateTimeString) {
      return DateTimeHelper.getMomentWithOffset(dateTimeString);
    },
    getPhotoUrlOrNoPhoto(url) {
      let noPhoto;
      if (url) {
        return url
      } else {
        noPhoto = require('../../../../assets/images/no-avatar-60x60.svg');
      }
      return noPhoto;
    },
    seeAllNotes() {
      this.$emit('seeAllNotes');
    },
    toggleNoteFav(id, note) {
      note.is_favorite = !note.is_favorite;
      if (!note.is_favorite) {
        this.$store.dispatch('notesStore/removeFav', id)
      } else {
        this.$store.dispatch('notesStore/addFav', id)
      }
    },
    getTruncatedText(text, length) {
      if (!text || !length || length <= 0 || text.length <= length) {
        return text;
      }
      return `${text.substring(0, length)}...`
    },
    linkifyUrls(inputString) {
      return ChatHelper.createLinks(inputString);
    },
    showText(id) {
      this.toggledId = id;
    },
    hideText() {
      this.toggledId = null;
    }
  }
}
</script>

<style scoped lang="scss">
  .field-block {
    margin-bottom: 3rem;
  }

  .field-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 8px;

    .title {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      color: $ew-black;
      opacity: 0.8;
      position: relative;

      &::before {
        display: block;
        position: absolute;
        left: -25px;
        bottom: 2px;
        content: "";
        border-bottom: 1px solid #eaeaea;
        width: 25px; /* Have to use precise units because we have overflow: visible on some field-blocks */
      }
    }

    .collapse-action-block {
      height: 18px;
      .collapse-action {
        font-size: 12px;
        height: auto;
      }
    }


    .fav {
      height: 24px;
    }
  }

  .note {
    margin-bottom: 4rem;

    &:last-child {
      margin-bottom: 0;
    }

    .note-title {
      font-weight: bold;
      font-size: 1.4rem;
    }

    .note-text {
      font-size: 1.4rem;
      word-break: break-all;
    }

  }


  .btn {
    text-align: left;
    font-size: 12px;
    color: $ew-blue;
    display: none;
  }

  .show-action {
    .btn {
      display: block;
    }
  }

  .note-head {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 2rem;

    .note-meta {
      flex: 1;

      .author {
        font-weight: bold;
        font-size: 1.2rem;
      }

      .date {
        font-size: 1.1rem;
        opacity: 0.5;
      }
    }
  }

  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 2rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
</style>
<style lang="scss">
  /* Unscoped workaround: vue-truncate-collapsed adds own whitespace — new lines and spaces */
  .truncate-scroll-height {
    height: 120px;
  }

  .collapse-action {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0;
    color: $ew-blue;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .note .note-text span {
    white-space: pre-wrap;
  }

  .note .note-text span.note-text-collapsed {
    white-space: normal;
  }
</style>
