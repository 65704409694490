


import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import LiveMediaBlock from '@/_modules/promo/components/live-media-block/live-media-block.vue';
import notesList from '@/views/components/promoPage/notes/notesList.vue';
import {TLivePage, TSponsor} from '@/_types/promo-page/live-page.type';
import {TNote} from '@/_types/store/note.type';
import {TEvent} from '@/_types/event.type';
import {TConferenceRoom} from '@/_modules/promo/types/conference-room.type';
import {TConferenceProgram} from '@/_modules/promo/types/conference-program.type';
import StreamPlayer from '@/_components/stream-player/stream-player.vue';
import {TEventRestrictions} from '@/_modules/events/api/event/event.api';
import {TContactPermissions} from '@/_modules/contacts/store/contacts.store';
import DateTimeHelper from '@/_helpers/date-time.helper';
import LobbyLiveBroadcasts from '@/_modules/promo/components/lobby-live-broadcasts/lobby-live-broadcasts.vue';
import ProgramMediaBlock from '@/_modules/promo-program/components/program-media-block/program-media-block.vue';
import FileHelper from '@/_helpers/file.helper';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import SearchBar from '@/_components/search-bar/search-bar.vue';
import NewsListLobby from '@/_modules/news/components/news-list-lobby/news-list-lobby.vue';
import PageTitle from '@/_components/page-title/page-title.vue';
import {TGetNotesParams} from '@/_api/notes/notes.api';
import {TApiListResponse} from '@/_types/api/api-list-response.type';

@Component({
  components: {
    SearchBar,
    PageTitle,
    LiveMediaBlock,
    notesList,
    StreamPlayer,
    LobbyLiveBroadcasts,
    ProgramMediaBlock,
    NewsListLobby,
  },
})

export default class CabinetLobby extends Vue {

  @Action('notesStore/getAllNotes') getAllNotes: (params: TGetNotesParams) => Promise<TApiListResponse<TNote>>;

  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/eventRestrictions') eventRestrictions: TEventRestrictions;
  @Getter('contactsStore/contactPermissions') contactPermissions: TContactPermissions;
  @Getter('promoProgramStore/conferenceRooms') conferenceRooms: TConferenceRoom[];
  @Getter('promoProgramStore/isLoading') isProgramLoading: boolean;
  @Getter('cabinetLobbyStore/isLivePageLoading') isLivePageLoading: boolean;
  @Getter('cabinetLobbyStore/livePageData') livePage: TLivePage;
  @Getter('notesStore/notesByUserId') readonly noteListByUserId: (userId: number) => TNote[];

  public isIntroMediaFullscreen: boolean = false;
  public isAgendaMediaFullscreen: boolean = false;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  // public readonly promoPageByExternalId: (externalId: string) => TPromoPage;
  // public getPromoPages: TPromoPage[];
  // public promoPages: TPromoPage[] = []

  public agendaScrollHeight: string = '';
  public noteSlides: TNote[][] = [];
  public notesQuantityInSlide: number = 3;
  public activeSponsorSlideNumber: number = 0;
  public activeNotesSlideNumber: number = 0;

  public get hasFullscreenMedia(): boolean {
    return this.isIntroMediaFullscreen || this.isAgendaMediaFullscreen;
  }

  public get sponsorsSlides(): TSponsor[][] {
    if (!this.livePage.sponsors) {
      return [];
    }

    const itemsPerChunk = 3;
    return Array.from({ length: Math.ceil(this.livePage.sponsors.length / itemsPerChunk) }, (value, index) => this.livePage.sponsors.slice(index * itemsPerChunk, index * itemsPerChunk + itemsPerChunk));
  }

  public get allProgramSessions(): TConferenceProgram[] {
    const allSessions: TConferenceProgram[] = [];
    (this.conferenceRooms || []).forEach(confRoom => {
      (confRoom.programs || []).forEach(program => {
        if (program && program.mediaItems) {
          allSessions.push(program);
        }
      })
    });
    return allSessions;
  }

  public get currentlyHappeningSessions(): TConferenceProgram[] {
    const dateNow = new Date();
    return this.allProgramSessions.filter(item => {
      return (dateNow >= new Date(item.date_start)) && (dateNow <= new Date(item.date_end));
    }).map(item => {
      return Object.assign({}, item, {mediaItem: {...[item.mediaItems]}});
    });
  }

  public get isAgendaSectionHavingVideo(): boolean {
    return !!(this.livePage.agenda_video_player_frame || this.livePage.agenda_video_file_url);
  }

  public get isIntroSectionHavingVideo(): boolean {
    return !!(this.livePage.video_player_frame || this.livePage.video_file_url);
  }

  public get isLobbyLiveSessionsSectionNotEmpty(): boolean {
    return !(this.currentlyHappeningSessions && this.currentlyHappeningSessions.length);
  }

  public get isLobbyNewsSectionNotEmpty(): boolean {
    return !!(this.noteSlides && this.noteSlides.length);
  }

  @Watch('eventId', {immediate: true})
  private onEventIdChange(): void {
    this.$store.dispatch('cabinetLobbyStore/getLivePage', this.eventId);

    this.$nextTick(() => {
      this.setAgendaScrollHeight();
    });

    window.addEventListener('resize', this.setAgendaScrollHeight);
  }

  public mounted(): void {
    window.addEventListener('resize', this.setAgendaScrollHeight);
    this.init();
  }

  public init(): void {
    if (!this.eventId) {
      return;
    }
    this.$store.dispatch('cabinetLobbyStore/getLivePage', this.eventId);
    this.$store.dispatch('promoPageStore/getPromoPageListAll', {eventId: this.eventId});
    this.$store.dispatch('promoProgramStore/loadProgram', {
      eventId: this.eventId,
      acceptLanguage: process.env.VUE_APP_I18N_FALLBACK_LOCALE || Vue.localStorage.get('language') || 'en'
    });
    this.initNotesForLobbyPage();
  }

  public beforeDestroy(): void {
    window.removeEventListener('resize', this.setAgendaScrollHeight);
  }

  public toggleMediaBlockFullscreen(blockName: 'intro' | 'agenda'): void {
    if (blockName === 'intro') {
      this.isIntroMediaFullscreen = !this.isIntroMediaFullscreen;
    } else if (blockName === 'agenda') {
      this.isAgendaMediaFullscreen = !this.isAgendaMediaFullscreen;
    }
  }

  public setAgendaScrollHeight(): void {
    let result = this.agendaScrollHeight; // default is taken from data
    const agendaPhotoArea = this.$refs.agendaPhotoArea;

    const heightPercentageOfWidth = 52.5; // Proportion is taken from design

    if (!agendaPhotoArea) {
      this.agendaScrollHeight = result;
      return;
    }

    let computedWidth: number = (agendaPhotoArea as HTMLElement).getBoundingClientRect().width;
    computedWidth = computedWidth <= 0 ? 0 : Math.floor(computedWidth) / 100 * heightPercentageOfWidth;

    if (computedWidth > 0) {
      result = computedWidth.toString() + 'px';
    }

    this.agendaScrollHeight = result;
  }

  public get noteListAll(): TNote[] {
    if (this.event && this.event.id) {
      return this.noteListByUserId(this.event.creator_user_id);
    }
    return [];
  }

  public async initNotesForLobbyPage(): Promise<void> {
    if (!this.event || !this.event.id) {
      window.setTimeout(this.initNotesForLobbyPage, 250);
      return;
    }
    await this.getAllNotes({
      eventId: this.eventId,
      queryParams: {
        user_id: this.event.creator_user_id
      }
    });

    this.createLobbyPageNoteSlides();
  }

  public createLobbyPageNoteSlides(): void {
    if (!this.noteListAll || !this.noteListAll.length) {
      return;
    }

    let amountOfSlides = 1; // Let there be at least one slide

    // Calculate the total amount of slides
    if (this.noteListAll.length > this.notesQuantityInSlide) {
      amountOfSlides = Math.ceil(this.noteListAll.length / this.notesQuantityInSlide);
    }

    // Create the array of amountOfSlides slides
    const result: TNote[][] = [];
    for (let i = 0; i < amountOfSlides; i++) {
      result.push([...this.noteListAll.slice(i * this.notesQuantityInSlide, i * this.notesQuantityInSlide + this.notesQuantityInSlide)]);
    }

    // Outputting the result into the data property
    this.noteSlides = result;
  }

  public setSponsorSlide(direction: 'prev' | 'next'): void {
    let newIndex: number = this.activeSponsorSlideNumber + (direction === 'prev' ? -1 : 1);
    newIndex = newIndex < 0 ? this.sponsorsSlides.length - 1 : newIndex;
    newIndex = newIndex > this.sponsorsSlides.length - 1 ? 0 : newIndex;

    this.activeSponsorSlideNumber = newIndex;
  }

  public onPreviousNoteClick(): void {
    if (this.activeNotesSlideNumber - 1 < 0) {
      return;
    }

    this.activeNotesSlideNumber--;
  }

  public onNextNoteClick(): void {
    if (this.activeNotesSlideNumber + 1 >= this.noteSlides.length) {
      return;
    }

    this.activeNotesSlideNumber++;
  }

  public handleSponsorSlideChange(index: number): void {
    this.activeSponsorSlideNumber = index;
  }

  public handleNotesSlideChange(index: number): void {
    this.activeNotesSlideNumber = index;
  }

  public getSessionDurationFormatted(startTime: Date, endTime: Date): string {
    return `${DateTimeHelper.getHoursMinutes(startTime)} - ${DateTimeHelper.getHoursMinutes(endTime)}`;
  }

  public get isImageType(): boolean {
    return FileHelper.isImage(this.livePage.video_file_url);
  }

  public get isAgendaImageType(): boolean {
    return FileHelper.isImage(this.livePage.agenda_video_file_url);
  }

  public generateLinksWithImagePreviews(text: string): string {
    return ChatHelper.createLinks(text);
  }

  public isPermissionRequired(thingName: string): boolean {
    const restrictionsData = (this.eventRestrictions && this.eventRestrictions.restrictions_data) || [];
    return restrictionsData.length && restrictionsData.indexOf(thingName) >= 0;
  }

  public hasPermission(thingName: string): boolean {
    const permissionsData = (this.contactPermissions && this.contactPermissions.permissions_data) || [];
    return permissionsData.length && permissionsData.indexOf(thingName) >= 0;
  }

  public canView(thingName: string): boolean {
    return !(this.isPermissionRequired(thingName) && !this.hasPermission(thingName));
  }
}
