


import { Component, Prop, Vue } from 'vue-property-decorator';
import { TNewsIssue } from '@/_types/news-issue.type';

const DEFAULT_AVATAR_NEWS = require('@/assets/images/default-news-image-60x60.svg');
const CUSTOM_DEFAULT_AVATAR_NEWS = require('@/assets/images/default-news-thumbnail.jpg');

@Component
export default class NewsThumbnail extends Vue {

  public get newsIssueId(): number {
    return this.$route.params.newsIssueId ? parseInt(this.$route.params.newsIssueId, 10) : null;
  }

  @Prop({ type: Boolean, default: false })
  public readonly isCustomDefaultImg: boolean;

  public get isExpanded(): boolean {
    if (!this.newsData) {
      return false;
    }
    return this.newsData.id === this.newsIssueId;
  }

  @Prop({ type: Object, default: {} })
  public readonly newsData: TNewsIssue;

  public get imageStyles(): {[key: string]: string} {
    let backgroundImageUrl = this.isCustomDefaultImg ? CUSTOM_DEFAULT_AVATAR_NEWS : DEFAULT_AVATAR_NEWS;
    if (this.newsData && this.newsData.photos && this.newsData.photos.length) {
      backgroundImageUrl = this.newsData.photos[0];
    }
    return {
      'background-image': 'url(' + backgroundImageUrl + ')'
    };
  }

  private onClick(newsIssueId: number): void {
    this.$router.push({
      name: 'news-issue',
      params: {
        newsIssueId: newsIssueId.toFixed(0)
      }
    }).catch(() => {
      /* ignore */
    });
  }

}
